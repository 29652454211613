import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/global';
import { OrderDetailPage } from 'src/app/order-detail/order-detail.page';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.page.html',
  styleUrls: ['./reports.page.scss'],
})
export class ReportsPage implements OnInit {
  @Input() orderData: any;
  constructor(private modalController: ModalController,
    public globals:GlobalVariable) { }

  ngOnInit() {
    // console.log(this.orderData)
    this.OrderList();
  }

  close(){
    this.modalController.dismiss();
  }
  OrderList() {
    this.globals.orders = this.orderData;
    this.globals.orders.map((listItem) => {
      if (listItem.adjustments != 'N/A') {
        listItem.adjustments.amount = parseFloat(listItem.adjustments.amount);
        listItem.adjustments.amount_charged = parseFloat(listItem.adjustments.amount_charged);
        listItem.total = Number(listItem.total)
      }
      return listItem;
    });
  

}
async orderdetail(item){

    const modal = await this.modalController.create({
    component: OrderDetailPage,
    componentProps: { data: item }
    });
  
    await modal.present();
  

}
}
