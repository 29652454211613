import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ManuService {
  superCategory: any;
  category: any;
  isEdit: any;
  singleCatId: any;
  superCategoryItem: any;
  categoryItem: any;
  editCate: any;
  isEditItem: any;
  item: any;

  private baseUrl: string = environment.apiEndPoint;

  constructor(private http: HttpClient) {}

  public getSuperCategories(
    businessId: string,
    isChowly: any
  ): Observable<any> {
    console.log('The id s', businessId);
    const endpoint = 'business_controller/getSuperCategories';
    const url = this.baseUrl + endpoint;

    let formData = new FormData();
    formData.append('business_id', businessId);
    if (isChowly) {
      formData.append('chowly_enabled', isChowly);
    }
    return this.http.post(url, formData);
  }

  public getCategories(businessId: any, superId: any): Observable<any> {
    console.log('The id s', businessId);
    const endpoint = 'menu/get_menu_categories';
    let formData = new FormData();
    formData.append('id', businessId);
    formData.append('super_category_id', superId);
    const url = this.baseUrl + endpoint;
    return this.http.post(url, formData);
  }

  public getItems(businessId: any, superId: any): Observable<any> {
    console.log('The id s', businessId);
    const endpoint = 'menu/get_menu_items';
    let formData = new FormData();
    formData.append('id', businessId);
    formData.append('category_id', superId);
    const url = this.baseUrl + endpoint;
    return this.http.post(url, formData);
  }
  public deleteSuperCategory(data): Observable<any> {
    const endpoint = `business_controller/delete_super_category`;
    const url = this.baseUrl + endpoint;
    console.log('The local is :::', url);
    let formData = new FormData();
    formData.append('from', 'api');
    formData.append('category_id', data);
    return this.http.post(url, formData);
  }

  addSuperCategory(data: any) {
    console.log('The local data is::', data);
    const endpoint = `business_controller/add_super_category`;
    const url = this.baseUrl + endpoint;
    console.log('The local is :::', url);
    return this.http.post(url, data);
  }

  getSingleSuperCategory(data: any) {
    console.log('The local data is::', data);
    const endpoint = `business/menu/getSuperCategoryDetail/${data}`;
    const url = this.baseUrl + endpoint;
    console.log('The local is :::', url);
    return this.http.get(url);
  }

  editSuperCategory(data: any) {
    console.log('The local data is::', data);
    const endpoint = `business_controller/update_super_category`;
    const url = this.baseUrl + endpoint;
    console.log('The local is :::', url);
    return this.http.post(url, data);
  }

  updateIsEdit(edit: any) {
    this.isEdit = edit;
  }
  public deleteCategory(id, catId): Observable<any> {
    const endpoint = `menu/delete_category`;
    const url = this.baseUrl + endpoint;
    let formData = new FormData();
    formData.append('id', id);
    formData.append('category_id', catId);
    console.log('The local is :::', url);
    return this.http.post(url, formData);
  }

  addCategory(data: any) {
    console.log('The local data is::', data);
    const endpoint = `menu/add_category`;
    const url = this.baseUrl + endpoint;
    console.log('The local is :::', url);
    return this.http.post(url, data);
  }
  updateCategory(data: any) {
    console.log('The local data is::', data);
    const endpoint = `menu/update_category`;
    const url = this.baseUrl + endpoint;
    console.log('The local is :::', url);
    return this.http.post(url, data);
  }

  addItem(data: any) {
    console.log('The local data is::', data);
    const endpoint = `menu/add_menu`;
    const url = this.baseUrl + endpoint;
    return this.http.post(url, data);
  }

  editItem(data: any) {
    console.log('The local data is::', data);
    const endpoint = `menu/update_menu`;
    const url = this.baseUrl + endpoint;
    return this.http.post(url, data);
  }

  deleteItem(bid, menu_id) {
    const endpoint = `menu/delete_menu`;
    const url = this.baseUrl + endpoint;
    let formData = new FormData();
    formData.append('id', bid);
    formData.append('menu_id', menu_id);
    return this.http.post(url, formData);
  }

  getItemExtras(bid, extraId) {
    const endpoint = `menu/get_menu_extras`;
    const url = this.baseUrl + endpoint;
    let formData = new FormData();
    formData.append('id', bid);
    formData.append('extras_id', extraId);
    return this.http.post(url, formData);
  }

  updateItemExtras(bid, extraId, data) {
    const endpoint = `menu/add_menu_extras`;
    const url = this.baseUrl + endpoint;
    let formData = new FormData();
    formData.append('id', bid);
    formData.append('menu_id', extraId);
    formData.append('extras', JSON.stringify(data));
    return this.http.post(url, formData);
  }
}
