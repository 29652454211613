import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private baseUrl: string = environment.apiEndPoint;
  amount: any;
  nonCashAdj: any;
  message: any;
  cardAmount: any;
  loggedIn: any = false;
  constructor(private http: HttpClient) {}
  public sendPaymentInformation(data): Observable<any> {
    const endpoint = `BusinessPaymentController/authorizeCreditCard
`;
    const url = this.baseUrl + endpoint;
    return this.http.post(url, data);
  }
  public chargePayment(data) {
    const endpoint = 'Authorize/charge_card_lite';
    const url = this.baseUrl + endpoint;
    return this.http.post(url, data);
  }
  public chargeTerminalPayment(data) {
    const endpoint = 'dejavoo/sale_request_lite';
    const url = this.baseUrl + endpoint;
    return this.http.post(url, data);
  }
  public loginLite(data) {
    const endpoint = 'Business/login_lite';
    const url = this.baseUrl + endpoint;
    return this.http.post(url, data);
  }
}
