import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  time;
  toast;
  constructor(private toastController:ToastController,) { }


  Check(){

   this.time = setTimeout(async ()=> {
    this.toast = await this.toastController.create({
      message:'taking more time than usuall.. maybe slow internet',
      color:'warning',
      duration:2000,
      position:'top'
    })
    this.toast.present();
    //  console.log('show toast. slow loaded data...')
    }, 8*1000);
  }

  clear(){
    // console.log('cleartimeout. loaded data...')
    clearTimeout(this.time)
  }
}
