import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../providers/server.service';
import { GlobalVariable } from '../global';


interface callBackData{
  name: String,
  phone: String,
  description: String,
}
@Component({
  selector: 'app-support-callback',
  templateUrl: './support-callback.page.html',
  styleUrls: ['./support-callback.page.scss'],
})
export class SupportCallbackPage implements OnInit {
  requestCallback: FormGroup;
  callbackData: callBackData;

  constructor(public modalCtrl: ModalController, public formBuilder: FormBuilder,public server: ServerService,
    public global:GlobalVariable, public loadCtrl: LoadingController, public alertCtrl: AlertController) { }

  ngOnInit() {
    this.requestCallback = this.formBuilder.group({
      name: new FormControl(
        '',
        Validators.compose([Validators.required]),
      ),
      phone: new FormControl(
        '',
        Validators.compose([Validators.required]),
      ),
      description: new FormControl(
        '',
        Validators.compose([Validators.required])
      )
    })
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
  submitCallBack(){
    console.log("Name is: ", this.requestCallback.value.name)
    console.log("Phone is: ", this.requestCallback.value.phone)
    console.log("Description is: ", this.requestCallback.value.description)
  }
}
