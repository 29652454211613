import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController, ToastController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import { OrderDetailPage } from '../order-detail/order-detail.page';
import { ServerService } from '../providers/server.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {
  to: any;
  @ViewChild(IonSearchbar)searchbar:IonSearchbar;
  searchResults=[]
  date: any;
  from: any;
  type: any = "order_id";
  month: any;
  keyword: any;
  constructor(private modalController:ModalController,private toastController: ToastController,
    public globals:GlobalVariable,
    private server:ServerService,) { 
    var today = new Date();
    this.month = today.getMonth() + 1;
    // console.log('Current month: ', this.month);

    if (this.month < 10) {
      this.date = today.getFullYear() + '-0' + (today.getMonth() + 1) + '-' + today.getDate();
      // console.log('Current Date: ', this.date);
    } else {
      this.date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      // console.log('Current Date: ', this.date);
    }

  
  }

  ngOnInit() {
    setTimeout(() => {
      this.searchbar.setFocus();
    }, 500);
  
  }
  close(){
    this.modalController.dismiss()
  }
  filter(e){
    // console.log(e)
    this.type=e.detail.value;
  }
  async onInput() {
    // if(this.from && this.to){
      this.searchResults=[];
    //   if(this.keyword!=''){
      this.globals.isDataLoaded=false;
    // console.log('Bar value: ', this.keyword);
    // console.log('Bar value: ', this.to);
    // console.log('Bar value: ', this.from);
    // console.log('Bar value: ', this.type);
    // let loading = this.loadingCtrl.create({
    //   content: "Loading...",
    // });
    // loading.present();
    // if(this.keyword!=''){
    let response = this.server.search(this.keyword, this.type, this.to, this.from);

    response.subscribe(async res => {
      this.globals.isDataLoaded=true;

      // loading.dismiss();
      if(res.success == true){
        // console.log("Orders", res);
        this.searchResults = res.orders;
        // console.log(" Data ", this.searchResults);
      }else{
        this.searchResults=[];
        const toast = await this.toastController.create({
          message: 'No results found.',
          duration: 2000,
          position: 'middle'
        });

        toast.present();
      }

      
    }, error => {
      this.globals.isDataLoaded=true;
      // loading.dismiss();
      this.globals.showToast("Something went wrong. Please check you connection");
    });
  // }
  // else{
  //   // console.log('empty keyword')
  // }
  // }
  // else{
  //   this.globals.showToast('Select start and end date','warning')
  // }
  }
  onCancel(event) {
    this.keyword = '';
    // this.type = '';
    // this.to = '';
    // this.from = '';
    this.searchResults = [];
  }
  async openOrder(orderData) {
    // console.log(orderData)
    const modal = await this.modalController.create({
      component: OrderDetailPage,
      componentProps: { data: orderData }
    });

    await modal.present();
    const data = await modal.onDidDismiss().then(() => {
      // this.getOrders()
    })
    // console.log('from item detail', data)



  }
}
